import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/pageheader"
import NewsItem from "../components/newsItem"
import CatNav from "../components/catnav"
import * as styles from "../styles/news.module.css"
import { graphql, Link } from "gatsby"
import { useLangContext } from "../context/langContext"

const NewsEventsTemplate = ({ data, pageContext }) => {
    const { lang } = useLangContext()
    const { currentPage, numPages } = pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    const newsPath = pageContext?.newsPath // current path
    const basicPath = pageContext?.basicPath
    const cats = []

    let wpPage
    if (lang && lang === "en") {
        pageContext?.categories?.data?.allWpCategory?.nodes.forEach(val => {
            cats.push({ name: val?.name, slug: `${basicPath}/${val?.slug}` })
        })

        wpPage = data?.wpPage?.blocks
    } else {
        pageContext?.categories?.data?.allWpCategory?.nodes.forEach(val => {
            const cat = val?.translations.find((_, idx) => idx === 0)

            cats.push({ name: cat?.name, slug: `${basicPath}/${val?.slug}` })
        })

        wpPage = data?.wpPage?.translations?.find((_, idx) => idx === 0)?.blocks
    }
    if (cats.length > 1) {
        cats.unshift({
            name: lang === "en" ? "Show All" : "Tampilkan Semua",
            slug: basicPath,
        })
    }

    return (
        <Layout activeMenuId={5}>
            {wpPage.map((item, i) => {
                switch (item?.name) {
                    case "acf/cover-main":
                        const coverMain = item?.coverMain
                        return (
                            <React.Fragment key={i}>
                                <Seo
                                    title={coverMain?.title}
                                    description={data?.wpPage?.seo?.metaDesc}
                                    date={data?.wpPage?.date}
                                    img={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaItemUrl
                                    }
                                    imgHeight={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.height
                                    }
                                    imgWidth={
                                        data?.wpPage?.seo?.opengraphImage
                                            ?.mediaDetails?.width
                                    }
                                />
                                <PageHeader
                                    title={coverMain?.title}
                                    subTitle={coverMain?.text}
                                    image={coverMain?.img?.mediaItemUrl}
                                    breadcrumbs={[
                                        { label: coverMain?.title, link: null },
                                    ]}
                                />
                            </React.Fragment>
                        )
                    case "core/shortcode":
                        return (
                            <div key={i} className="container mx-auto">
                                <div className="text-center mb-16">
                                    <CatNav
                                        catsList={cats}
                                        activeCat={newsPath}
                                    />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0">
                                    {data?.allWpPost?.edges.map((val, key) => {
                                        let post = val?.node
                                        let slug = post?.slug
                                        if (
                                            lang === "id" &&
                                            val?.node?.translations.length > 0
                                        ) {
                                            post = val?.node?.translations[0]
                                        }
                                        const maxTitleLetter = 50
                                        const title =
                                            post?.title.length > maxTitleLetter
                                                ? post?.title.substring(
                                                      0,
                                                      maxTitleLetter
                                                  ) + "..."
                                                : post?.title
                                        return (
                                            <div key={key} className="mb-8">
                                                <NewsItem
                                                    image={
                                                        post?.featuredImage
                                                            ?.node?.sourceUrl
                                                    }
                                                    imageAlt={
                                                        post?.featuredImage
                                                            ?.node?.altText
                                                    }
                                                    title={title}
                                                    link={`${basicPath}/${slug}`}
                                                    date={post?.date}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                                <div>
                                    <ul
                                        className={`text-center my-16 ${styles.pagination}`}
                                    >
                                        <li
                                            className={styles.prev}
                                            style={
                                                isFirst
                                                    ? { display: "none" }
                                                    : {}
                                            }
                                        >
                                            <Link
                                                to={
                                                    isFirst
                                                        ? ""
                                                        : `${newsPath}/${prevPage}`
                                                }
                                                rel="prev"
                                            >
                                                {" "}
                                            </Link>
                                        </li>
                                        {/*<li className={styles.pageItem}>
                                            <span className="">1</span>
                                        </li>
                                        <li className={styles.pageItem}>
                                            <a href="#">2</a>
                                        </li>
                                        <li className={styles.pageItem}>
                                            <a href="#">3</a>
                                        </li>
                                        <li className={styles.pageItem}>
                                            <a href="#">4</a>
                                        </li>*/}
                                        <li
                                            className={styles.next}
                                            style={
                                                isLast
                                                    ? { display: "none" }
                                                    : {}
                                            }
                                        >
                                            <Link
                                                to={
                                                    isLast
                                                        ? ""
                                                        : `${newsPath}/${nextPage}`
                                                }
                                                rel="prev"
                                            >
                                                {" "}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )
                }
            })}
        </Layout>
    )
}

export const query = graphql`
    query newsListQuery($skip: Int!, $limit: Int!, $categoriesSlug: [String]) {
        wpPage(slug: { eq: "news-events" }) {
            date
            seo {
                title
                metaDesc
                opengraphImage {
                    id
                    mediaItemUrl
                    mediaDetails {
                        height
                        width
                    }
                }
            }
            blocks {
                name
                ... on WpAcfCoverMainBlock {
                    coverMain {
                        img {
                            altText
                            mediaItemUrl
                        }
                        text
                        title
                    }
                }
                ... on WpAcfTextWColCardsBlock {
                    textWColCards {
                        cards {
                            title
                            link {
                                url
                            }
                            img {
                                altText
                                mediaItemUrl
                            }
                        }
                    }
                }
                ... on WpCoreShortcodeBlock {
                    attributes {
                        text
                    }
                }
            }
            translations {
                blocks {
                    name
                    ... on WpAcfCoverMainBlock {
                        coverMain {
                            img {
                                altText
                                mediaItemUrl
                            }
                            text
                            title
                        }
                    }
                    ... on WpAcfTextWColCardsBlock {
                        textWColCards {
                            cards {
                                title
                                link {
                                    url
                                }
                                img {
                                    altText
                                    mediaItemUrl
                                }
                            }
                        }
                    }
                    ... on WpCoreShortcodeBlock {
                        attributes {
                            text
                        }
                    }
                }
            }
        }
        allWpPost(
            limit: $limit
            skip: $skip
            sort: { fields: date, order: DESC }
            filter: {
                language: { code: { eq: EN } }
                categories: {
                    nodes: { elemMatch: { slug: { in: $categoriesSlug } } }
                }
            }
        ) {
            edges {
                node {
                    slug
                    title
                    date(formatString: "DD MMMM YYYY")
                    language {
                        name
                    }
                    categories {
                        nodes {
                            name
                            slug
                        }
                    }
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                        }
                    }
                    translations {
                        slug
                        title
                        date(formatString: "DD MMMM YYYY", locale: "id")
                        language {
                            name
                        }
                        categories {
                            nodes {
                                name
                                slug
                            }
                        }
                        featuredImage {
                            node {
                                altText
                                sourceUrl
                            }
                        }
                    }
                }
            }
        }
    }
`

export default NewsEventsTemplate
